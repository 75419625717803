
.pro-report-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
}

.pro-header {
    padding: .1rem;

    
    /*    opacity: .9;
*/
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 97;
}

.navbar-position{
    position: fixed;
    left: 690px;
    font-size: 15px;
}

.pro-report-viewer {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    grid-row-start: 2;
    grid-row-end: 3;
    overflow: hidden;
    position: fixed;
    top: 235px; /*was 75*/
    height: 70vh;
    width: 100vw;
    /*z-index: 150;*/
}

    .pro-report-viewer .report {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        /*z-index: 150;*/
    }

::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
}    

::-webkit-scrollbar-track {
    border-radius: 0;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /*border: 2px solid #028ae6;*/
    /*background: #028ae6;*/
    background: #F5F5F5;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: lightgray;
    }

.active-filters {
    /*position: fixed;*/
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    /*flex-flow: row nowrap;*/
    flex-direction: row;
    flex-wrap: nowrap;
    width: 94vw;
    max-width: 94vw;
    top: 74px;
    left: 124px;
    /*height: 50px;*/
    height: 90%;
    padding: 0px !important;
    grid-column-start: 2;
    grid-row-start: 2;
    z-index: 94;
}
.active-filters-wrapper {
    position: fixed;
    top: 76px;
    left: 30px;
    height: 50px;
    width: 97vw;
    border: solid;
    border-radius: 6px;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%) !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    overflow: hidden;
    align-items: center;
    grid-template-columns: 50px 1fr;
    z-index: 94;
}
.active-filters-header {
    /*position: fixed;*/
    top: 76px;
    left: 30px;
    height: 50px;
    width: 90px;
    z-index: 94;
    background-color: #00395c;
    color: white;
    font-size: 18px;
    font-weight: bolder;
    border: solid;
    border-radius: 6px 0px 0px 6px;
    border-width: 1px;
    /*border-color: hsl(0, 0%, 80%) !important;*/
    border-color: #00395c !important;
    text-align: center;
    padding-top: 10px;
    padding-left: 5px;
}
.active-filters-spacer {
    position: fixed;
    top: 76px;
    left: 118px;
    height: 50px;
    width: 5px;
    z-index: 94;
    border-top: solid;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%) !important;
    margin-right: 10px;
    background-color: #028ae6;
}

.filter-tag {
    flex: 0 0 auto;
    border: 1px solid transparent !important;
    background-color: white;
    color: black;
    background-color: transparent;
    font-weight: 500;
    opacity: .8;
    height: 45px;
    max-height: 45px;
    max-width: 450px;
    display: grid;
    margin-top: -4px;
    padding-top: 0px !important;
    grid-template-rows: none;
    grid-template-columns: auto 25px;
}

    .filter-tag:hover {
        border: 1px solid black;
        background-color: lightgray;
    }


.tag-text {
    grid-column: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
}
.tag-header {
    margin: 0px;
    color: hsl(0, 0%, 60%) !important;
}

.remove-tag {
    grid-column: 2;
    z-index: 98;
    /*margin-left:-3px;*/
    margin-top:5px;
}


.pro-brand {
    height: 2em;
}

.pro-title {
    font-size: 15px;
}

.pro-page-link {
    border-bottom: 1px solid #212121;
    margin-left: 1rem;
    color: white;
}

    .pro-page-link:hover {
        border-bottom: 1px solid orange;
        cursor: pointer;
    }

pro-page-link-active:hover {
    cursor: default;
    border-bottom: 1px solid #212121;
}

.pro-footer {
    font-size: small;
    grid-row-start: 3;
}



.nav-link {
    /*border-bottom: 1px solid #212121;*/
    margin-left: 1rem;
    padding: 0;
}

    /*navbar items use this for hover    */
    .nav-link:hover {
        border-bottom: 1px solid rgb(247,174,57);
    }


.embed-header {
    opacity: .9;
    font-weight: bolder;
    font-size: large;
}

.dropdownz {
    z-index: 2800 !important;
}

