.carousel .slide .legend {
    opacity: unset;
}

.upgrade-container {
    display: grid;
    grid-template-rows: 50px 1fr 100px;
}

.upgrade-content {
    margin-top: 10px;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.upgrade-header {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    height: 50px;
}

.upgrade-footer {
}

.carousel-fix {
    height: 50%;
    max-width: 75%;
    grid-column-start: 2;
    grid-column-end: 3;
}

.required:after {
    content: " *";
    color: red;
}
