.top-level-splash {
    position: fixed;
    /*height: 575px;*/
    width: 100vw;
    height: 100vh;
    font-size: 100px;
    min-height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-image: url("./assets/img/Splash.jpg");
    /*background-color: black;*/
    /*background-size: cover;
    resize: both;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;*/
    /*overflow: scroll;*/
}
.head {
    position: fixed;
    top: 23.44vw;/*450px;*/
    left: 26.04vw;/*500px;*/
    color: #fed13f;
    font-size: 2.34vw;/*45px;*/
}
.title {
    position: fixed;
    top: 28.65vw;/*550px;*/
    left: 30.21vw;/*580px;*/
    color: #fed13f;
    font-size: 6.51vw;/*125px;*/
}

.blurb {
    position: fixed;
    top: 34.9vw;/*670px;*/
    left: 58.59vw;/*1125px;*/
    color: #fed13f;
    font-style:italic;
    font-size: 3.13vw;/*60px;*/
}
.copyright {
    position: fixed;
    bottom: 1.3vw;/*25px;*/
    right: 3.91vw;/*75px;*/
    color: black;
    font-size: 0.94vw;/*18px;*/
    z-index:99;
}
.button1 {
    position: fixed;
    z-index: 98;
    bottom: 6.25vw; /*120px;*/
    right: 3.39vw; /*65px;*/
    font-size: 0.78vw; /*15px;*/
    border-radius: 1.00vw; /*7.5px;*/
    border: none;
    color: black;
    background-color: #fed13f;
    width: 4.11vw; /*79px;*/
    height: 1.77vw; /*34px;*/
}
.button2 {
    position: fixed;
    z-index: 98;
    bottom: 3.65vw; /*70px;*/
    right: 3.39vw; /*65px;*/
    font-size: 0.78vw; /*15px;*/
    border-radius: 1.00vw; /*7.5px;*/
    border: none;
    color: black;
    background-color: #fed13f;
    width: 4.11vw; /*79px;*/
    height: 1.77vw; /*34px;*/
}