.top-level-rp-new {
    position: fixed;
    height: 29.95vw;/*575px;*/
    width: 100vw;
    top: 44px;/* 2.82vw;*/
    left: 0px;
    z-index: 94;
    /*background-image: url("../assets/img/background_pro.jpg");*/
    background-color: white;
    background-size: cover;
    resize: both;
    height: 91.3vh;
    min-height: 91.3vh;
    width: 100vw;
    /*overflow: scroll;*/
}

.container-rate-new {
    position: fixed;
    top: 3.26vw;
    left: 0.45vw;
    width: 99vw;
    height: 88vh;
    border: 1px solid;
    border-radius: 10px 10px 0px 0px;
    background-color: rgba(255,255,255, 0.4);
}

.rate-pred-modal {
    /*height: 88vh !important;*/
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.btn-cancel-new {
    margin-left: 0.26vw; /*5px;*/
    border: 1px, solid white;
}

.labels {
    position: fixed;
    top: 6.21vw;
    left: 1.20vw;
}

.inputs {
    position: fixed;
    top: 7.51vw;
    left: 1.05vw;
}
.buttons {
    position: fixed;
    top: 3.60vw;
    right: 3.13vw;
}

.lbl-new {
    color: black;
    background-color: transparent;
    font-size: 0.75vw; /*10px;*/
    font-family: "Roboto",sans-serif;
    position: relative;
    left: 0px;
    width: 10.75vw;
    /*height: 3.25vw;*/
    font-weight: 300;
}

.lbl2-new {
    color: black;
    background-color: transparent;
    font-size: 1.5vw; /*30px;*/
    position: relative;
    left: 0px;
    font-weight: 600;
    width: 18.75vw; /*360px;*/
}

.lbl-result-new {
    color: black;
    background-color: transparent;
    font-size: 1.5vw; /*30px;*/
    position: relative;
    left: 0px;
    font-weight: 500;
}

.input-new {
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    color: black;
    background-color: rgba(255,255,255, 0.3);
    position: relative;
    left: 0px;
    width: 10.75vw; /*360px;*/
    height: 2.00vw; 
    font-weight: 600;
    font-size: 1.25vw; /*25px;*/
}


/*.input.background {
    opacity: 0.1;
}*/
.slider-new {
    position: fixed;
    top: 700px;
    width: 430px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}

.opt-new {
    color: black;
    background-color: transparent;
}




.header-new {
    position: relative;
    /*left: 5px;
    top: 10px;*/
    height: 2.7vw;
    width: 99vw;
    /*color: white;*/
    background-color: #054075;
    border-radius: 10px 10px 0px 0px;
    text-align: left;
    padding: 0px;
    margin: 0px;
    border: none;
    /*margin: auto;*/

    display: block;
    clear: both;
}

.header-text-new {
    position: relative;
    left: 15.5px;
    padding-top: 10px;
    height: 2.7vw;
    width: 100vw;
    color: white;
    font-size: 1.13vw;
    font-weight: 400;
    text-align: left;
    /*padding: 0px;
    margin: 0px;
    border: none;
    display: block;
    clear: both;*/
}


.header2-new {
    position: relative;
    /*left: -30px;*/
    color: black;
    font-size: 1.56vw; /*30px;*/
    background-color: transparent;
    text-align: center;
    /*margin: auto;*/
    width: 35.68vw; /*685px;*/
    display: block;
    clear: both;
}

.MPact-new {
    position: fixed;
    top: 1.3vw; /*25px;*/
    left: 0.52vw; /*10px;*/
}

.background-new {
    /*background-image: url("../assets/img/background.jpg");
    background-image: url("../assets/img/background_pro.jpg");*/
}



.result-container-new {
    color: white;
}

.result-new {
    color: black;
    font-size: 1.82vw; /*35px;*/
    position: relative;
    left: 0px;
    width: 15.63vw; /*300px;*/
    text-align: right;
}

h2 {
    display: inline;
}

.button-pro-rp-new {
    position: fixed;
    top: 0px;
    right: 4.95vw; /*95px;*/
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
}

/*.button-pro-rp-base {
    position: fixed;
    top: 0px;
    right: 4.95vw;*/ /*95px;*/
    /*background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
}*/

.button-pro-rp-new:hover {
    border: none;
}

.button-base-rp-new {
    position: fixed;
    bottom: 2.03vw; /*39px;*/
    right: 1.15vw; /*138px;*/
    z-index: 95;
    background-color: transparent;
    border: none !important;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
    height: 22.5px !important;
    width: 75px !important;
}

    .button-base-rp-new:hover {
        border: none;
    }

.button-image-rp-new {
    width: 4.7vw; /*90px;*/
    height: 2.6vw; /*50px;*/
    /*width: 90px;
    height: 50px;*/
}

.button-submit-new {
    position: relative;
    left: 0px;
    width: 7.05vw; /*120px;*/
    height: 1.56vw; /*30px;*/
    text-align: center;
    line-height: 0.13vw; /*5px;*/
}

.button-cancel-new {
    position: relative;
    left: 0px;
    width: 7.05vw; /*120px;*/
    height: 1.56vw; /*30px;*/
    text-align: center;
    --cui-btn-bg: transparent;
    line-height: 0.26vw; /*5px;*/
}

.center-rp-new {
    position: absolute;
    top: -0.31vw; /*6px;*/
    right: -29.69vw;
    height: 22px;
    width: 22px;
    z-index: 99;
    background-color: black; /*#fed13f */
    color: white;
    border: thick;
    border-width: thick;
    border-color: black;
}

    .center-rp-new:hover {
        background-color: red;
    }
.centerRP {
    position: absolute;
    top: 0.31vw; /*6px;*/
    right: -2.08vw; /*-40px;*/
    height: 1.55vw; /*22px;*/
    width: 1.55vw; /*22px;*/
    z-index: 99;
}
.centerRP:hover {
    background-color: red;
}
.dropdown-container {
    position: relative !important;
    width: 10.75vw;
    height: 2.00vw !important;
    left: 0.50vw !important; 
    z-index: 99;
    background-color: transparent;
    font-size: 0.75vw;
    font-weight: bold;
}

.dropdown-container2 {
    position: relative !important;
    width: 10.75vw;
    height: 2.00vw !important;
    left: 1.2vw !important; 
    z-index: 99;
    background-color: transparent;
    font-size: 0.75vw;
    font-weight: bold;
    margin-top: 3px;
}


.label-plus-input {
    position: relative;
    display: block;
    /*padding-top: -10px;*/
    width: 10vw;
    top: .225vw;
}
.lbl-cc {
    display: block;
    font-size: 0.7vw;
    position: relative !important;
    left: 0.5vw !important;
}
.input-cc {
    display: block;
    height: 4vh;
}
.row-input {
    left: 0vw;
    width: 96vw;
}
.container-input {
    position: fixed;
    top: 12vh;
    left: 0.63vw;
    width: 80vw;
    max-width: 80vw;
    z-index: 98;
}
.dsDropdownLabel {
    font-size: 0.625vw;
    font-weight: 300;
    /*width: 10.75vw;*/
    /*height: 2.00vw;*/
}

.input2-new {
    left: 0.50vw !important;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    color: black;
    background-color: rgba(255,255,255, 0.3);
    position: relative;
    width: 10.75vw;
    height: 38px;
    font-size: 0.75vw;
    font-weight: bold;
}


.input3-new {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    color: black;
    background-color: rgba(255,255,255, 0.3);
    position: relative;
    width: 10.75vw;
    height: 2.00vw;
    font-size: 0.75vw;
    font-weight: bold;
}

/*Original from here down from ratepredictor.css*/
/*Original from here down from ratepredictor.css*/
/*Original from here down from ratepredictor.css*/
/*Original from here down from ratepredictor.css*/
/*Original from here down from ratepredictor.css*/

.btn {
    z-index: 99;
}

.btn-primary {
    margin-left: 5px;
}

.btn-cancel {
    /*margin-left: 5px;
    --cui-btn-bg: transparent;*/
    background-color: transparent !important;
    margin-left: 0.26vw; /*5px;*/
    border: 1px solid white !important;
    line-height: 0.15vw;
}

.lbl {
    color: black;
    background-color: transparent;
    font-size: 1.5vw; /*30px;*/
    position: relative;
    left: 0px;
    width: 18.75vw; /*360px;*/
    font-weight: 600;
}

.lbl2 {
    color: black;
    background-color: transparent;
    font-size: 1.5vw; /*30px;*/
    position: relative;
    top: -13px;
    left: 0px;
    font-weight: 600;
    width: 18.75vw; /*360px;*/
}

.lbl-result {
    color: black;
    background-color: transparent;
    font-size: 1.5vw; /*30px;*/
    position: relative;
    left: 0px;
    font-weight: 500;
}

.input {
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    color: black;
    background-color: rgba(255,255,255, 0.3);
    position: relative;
    left: 0px;
    font-weight: 600;
    font-size: 1.3vw; /*25px;*/
}

/*.input.background {
    opacity: 0.1;
}*/
.slider {
    position: relative;
    left: 0px;
    width: 430px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}

.opt {
    color: black;
    background-color: transparent;
}

.container-rate {
    position: fixed;
    top: 56px;
    left: 34vw; /*660px;*/
    background-color: rgba(255,255,255, 0.4);
}



.header {
    position: relative;
    /*left: -30px;*/
    color: black;
    font-size: 2.6vw; /*50px;*/
    font-weight: 600;
    background-color: transparent;
    text-align: center;
    padding: 0px;
    margin: 0px;
    border: none;
    /*margin: auto;*/
    height: 3.7vw; /*71.45px;*/
    width: 34.6vw; /*685px;*/
    display: block;
    clear: both;
}

.header2 {
    position: relative;
    /*left: -30px;*/
    color: black;
    font-size: 30px;
    background-color: transparent;
    text-align: center;
    /*margin: auto;*/
    width: 685px;
    display: block;
    clear: both;
}

.MPact {
    position: fixed;
    top: 25px;
    left: 10px;
}

.top-level-rp {
    position: fixed;
    height: 575px;
    width: 100vw;
    top: 43px; /*<<<<<started at 56 but moved to 43 to accommodate how it presents in Java<<<<<<<*/
    left: 0px;
    z-index: 93;
    background-image: url("../assets/img/background_pro.jpg");
    background-color: black;
    background-size: cover;
    resize: both;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
}

.result-container {
    position: fixed;
    top: 22.00vh;
    left: 46.00vw;
    color: black;
    z-index: 97;
}

.result {
    color: black;
    font-size: 35px;
    position: relative;
    left: 0px;
    width: 300px;
    text-align: right;
}

h2 {
    display: inline;
}

.button-pro-rp {
    position: fixed;
    top: 15px;
    left: 1091px;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
    height: 22.5px;
    width: 75px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid transparent !important;
    text-align: left;
}

    .button-pro-rp:hover {
        border-bottom: 1px solid rgb(247,174,57) !important;
    }

.button-image-rp {
    /*width: 90px;   this is for yellow icon
    height: 50px;    this is for yellow icon*/
    width: 75px;
    height: 20px;
    color: white;
    border-bottom: 2px !important;
    border-bottom-color: red !important;
    z-index: 140;
}
    /*.button-image-rp:hover {
        border-bottom: 1px !important;
        border-bottom-color: red !important;
        color: #ffc107;*/
        /*border-bottom-color: #ffc107 !important;*/
        /*z-index: 140;
    }*/

.button-image-rp-base {
    /*width: 90px;   this is for yellow icon
    height: 50px;    this is for yellow icon*/
    width: 35px;
    height: 35px;
    color: white;
    border-bottom: 2px !important;
    border-bottom-color: red !important;
    z-index: 140;
}

.button-image-rp-base:hover {
    cursor: pointer;
}

.button-submit {
    position: relative;
    left: 0px;
    width: 120px;
    height: 40px;
}

.button-cancel {
    position: relative;
    left: 0px;
    width: 120px;
    height: 40px;
}

.center-rp {
    position: absolute;
    top: -0.31vw; /*6px;*/
    right: -29.69vw;
    height: 22px;
    width: 22px;
    z-index: 98;
    background-color: black; /*#fed13f */
    color: white;
    border: thick;
    border-width: thick;
    border-color: black;
}

    .center-rp:hover {
        background-color: red;
    }

.support-button {
    position: fixed;
    z-index: 180;
    top: 4px;
    right: 36.5px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
}
.button-image-sb {
    width: 35px;
    height: 35px;
}
.btn-border-none {
    border: none !important;
}
.kma-orig {
    position: fixed;
    font-size: 0.725vw;
    font-weight: 300;
    z-index: 97;
    top: 192px;
    left: 520px;
}
.kma-dest {
    position: fixed;
    font-size: 0.725vw;
    font-weight: 300;
    z-index: 97;
    top: 192px;
    left: 757px;
}

.first {
    text-transform: capitalize;
    display: inline-block;
    font-size: 15px;
    font-family: "Roboto",sans-serif;
    font-weight: 300;
}
.second {
    text-transform: capitalize;
    display: inline-block;
    font-size: 15px;
    font-family: "Roboto",sans-serif;
    font-weight: 300;
}
.third {
    text-transform: capitalize;
    display: inline-block;
    font-size: 15px;
    font-family: "Roboto",sans-serif;
    font-weight: 300;
    white-space: pre;
}
.fourth {
    text-transform: capitalize;
    display: inline-block;
    font-size: 15px;
    font-family: "Roboto",sans-serif;
    font-weight: 300;
    white-space: pre;
    padding-left: 7.5px;
}

    /*.fourth:hover {
        border-bottom: 2px !important;
        color: red;
        border-bottom-color: red !important;*/
        /*border-bottom-color: #ffc107 !important;*/
    /*}*/