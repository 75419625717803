.card14 {
    width: 13.975vw;
    max-width: 13.975vw;
    border-right: 1px solid #cdcdcd;
    background-color:transparent;
    padding: 0;
}
.card14:last-child {
    border: none !important;
}
.container14 {
    width: 13.75vw;
    max-width: 13.75vw;
    height: 8.50vw;
    padding: 0px,0,0,0;
    margin: 0px;
    
}

/*.gray{
    background-color: #f9f9f9;
}*/
.odd {
    background-color: #f9f9f9;
}
.even {
    /*background-color: #f9f9f9;*/
}
.today {
    background-image: url("../assets/img/todays_color.png");
    background-repeat:no-repeat;
    background-color: #137CDD;
}
.row14-header {
    width: 13.42vw;
    max-width: 13.42vw;
    height: 2.5vh;
}

.row14-top {
    width: 13.57vw;
    max-width: 13.57vw;
    height: 3vh;
}

.row14 {
    width: 13.57vw;
    max-width: 13.57vw;
}

.col14-1 {
    width: 0.5vw;
    padding: 3px;
    text-align: left;
    position: relative;
    top: -5px;
    left: 0.38vw;
}

.col14-2 {
    width: 0.8vw;
    text-align: right;
    padding: 0px 6px 0px 6px;
    left: -8px;
}

.col14-2b {
    width: 0.8vw;
    padding: 0px 6px 0px 6px;
    left: -8px;
    text-align: center;
}

.col14-3 {
    width: 0.8vw;
    text-align: right;
    padding: 0px 6px 0px 6px;
    left: -8px;
}

.col14-3b {
    width: 0.8vw;
    padding: 0px 6px 0px 6px;
    left: -8px;
    text-align: center;
}

.col14-4 {
    width: 0.8vw;
    padding: 0px 6px 0px 6px;
}
.date14 {
    position: relative;
    /*left: -120px;*/
    text-align: left;
    top: 3px;
    width: 12.00vw;
    max-width: 13.3vw;
    font-size: 0.75vw; /*12px;*/
    font-weight: bold;
    background-color: transparent;
    color: #137CDD;
    font-family: "Roboto",sans-serif;
    /*border-radius: 7.5px 7.5px 0px 0px;*/
}
.date14-today {
    position: relative;
    /*left: -120px;*/
    text-align: left;
    top: 3px;
    width: 12.00vw;
    max-width: 13.3vw;
    font-size: 0.75vw; /*12px;*/
    font-weight: 800;
    background-color: transparent;
    color: white;
    font-family: "Roboto",sans-serif;
    /*border-radius: 7.5px 7.5px 0px 0px;*/
}

.head14 {
    display: inline-flex;
    flex-direction: row;
    font-family: "Roboto",sans-serif;
}
.head14-today {
    display: inline-flex;
    flex-direction: row;
    font-family: "Roboto",sans-serif;
    color: white;
}
.buy14 {
    display: inline-flex;
    flex-direction: row;
}
.buy14-today {
    display: inline-flex;
    flex-direction: row;
    color: white;
}
.buy14-today-pred {
    display: inline-flex;
    flex-direction: row;
    color: #FAFF00;
}


.sell14 {
    display: inline-flex;
    flex-direction: row;
}

.row0-14 {
    /*display: inline-flex;
    flex-direction: row;*/
    position: fixed;
    top: 10.50vw;
    left: 22.5px;
    display: flex;
    z-index: 93;
    /*justify-content: space-between;*/
}
.row1-14 {
    /*display: inline-flex;
    flex-direction: row;*/
    position: fixed;
    top: 13.00vw;
    display: flex;
    left: 0.63vw; /*10px;*/
    font-family: "Roboto",sans-serif;
    z-index: 93;
    /*justify-content: space-between;*/
}
.row2-14 {
    /*display: inline-flex;
    flex-direction: row;*/
    position: fixed;
    top: 21.5vw;
    display: flex;
    left: 0.63vw; /*10px;*/
    font-family: "Roboto",sans-serif;
    z-index: 93;
    /*justify-content: space-between;*/
}
.row3-14 {
    /*display: inline-flex;
    flex-direction: row;*/
    position: fixed;
    top: 30.02vw;
    display: flex;
    left: 0.63vw; /*10px;*/
    font-family: "Roboto",sans-serif;
    z-index: 93;
    /*justify-content: space-between;*/
}
.h1-14 {
    font-size: 1.13vw; /*18px;*/
    font-weight: bold;
    color: rgba(0,0,0,1);
    font-family: "Roboto",sans-serif;
}
h3 {
    font-size: 18px;
    font-size: 1.13vw; /*18px;*/
    color: rgba(0,0,0,1);
    font-weight: bold;
    font-family: "Roboto",sans-serif;
}
h5 {
    font-size: 0.63vw; /*10px;*/
    color: rgba(0,0,0,0.65);
    font-weight: bold;
    font-family: "Roboto",sans-serif;
}
h6 {
    font-size: 0.75vw; /*12px;*/
    color: rgba(0,0,0,1); /*rgba(0,0,0,0.95);*/
    /*font-weight: bold;*/
    font-family: "Roboto",sans-serif;
}