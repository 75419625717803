html {
    scrollbar-face-color: #646464;
    scrollbar-base-color: #646464;
    scrollbar-3dlight-color: #646464;
    scrollbar-highlight-color: #646464;
    scrollbar-track-color: #000;
    scrollbar-arrow-color: #000;
    scrollbar-shadow-color: #646464;
    scrollbar-dark-shadow-color: #646464;
}

::-webkit-scrollbar {
    width: 8px;
    height: 3px;
}

::-webkit-scrollbar-button {
    background-color: #666;
}

::-webkit-scrollbar-track {
    background-color: #646464;
}

::-webkit-scrollbar-track-piece {
    background-color: #000;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #666;
    border-radius: 3px;
}

::-webkit-scrollbar-corner {
    background-color: #646464;
}

::-webkit-resizer {
    background-color: #666;
}

.pos-absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}


.bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.bg-report {
    background-image: url("assets/img/background.jpg")
}


.bg-report-pro {
    z-index: 180;
    /*background-image: url("assets/img/background_pro.jpg")*/
}



.bg-login {
    background-image: url("assets/img/background_01.jpg");
}

.bg-2 {
    background-image: url("assets/img/background_02.jpg");
}

.bg-3 {
    background-image: url("assets/img/background_03.jpg");
}

.bg-4 {
    background-image: url("assets/img/background_04.jpg");
}


.app-header {
    grid-row-start: 1;
    background-color: transparent;
    padding: .25em;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

    .app-header nav {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
    }

.header-logo {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: .75em;
}


.app-footer {
    grid-row-start: 3;
    background-color: transparent;
    padding: .25em;
    text-align: center;
}

.footer-logo {
    margin: .25em;
    opacity:.2;
}

.footer-text {
    font-size:xx-small;
    color:white;
}

.app-content {
    grid-row-start: 2;
    overflow: auto;
    display: grid;
}


.full-grid {
    grid-column: 1/-1;
    grid-row: 1/-1;
}

.report-side-nav {
    background-color: #202020;
}

.report-card {
    cursor: pointer;
    border-radius: initial;
    opacity: .85;
}

    .report-card:hover {
        border: .5px solid #202020;
    }


.cursor-pointer {
    cursor: pointer;
}

.upgrade-right {
    text-align: right;
    right: 5px !important;
    top: 5px !important;
    width: 180px !important;
    background-color: #50A1EB !important;
    box-shadow: 0px 0px 5px 3px #FFA500 !important;
}
.upgrade-right:hover {
    background-color: #0B60AD !important;
}


.button-left {
    left: 5px !important;
    top: 5px !important;
    background-color: #50A1EB !important;
}
    .button-left:hover {
        background-color: #0B60AD !important;
    }

/* Report viewer */
.report-page {
    display: grid;
    grid-template-rows: max-content auto max-content;
    overflow: hidden;
}

.report-viewer {
    height: 100%;
    width: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    grid-template-columns: 1fr minmax(250px,500px) 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.report {
    grid-column: 1/-1;
    grid-row: 1/-1;
}

.report-loading-placeholder {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.report-toolbar {
    padding: .1rem;
    background-color: transparent;
    color: white;
    opacity: .9;
}

    .report-toolbar select {
        margin-left: 1em;
        background-color: #202020;
        color: white;
    }


.embed-loading {
    opacity: 0;
}

.embed-rendered {
    z-index: 10;
    opacity: 1;
}


.loading-animation {
    animation: pulse-animation 1.25s infinite ease-out;
}

@keyframes pulse-animation {
    0% {
        transform: scale(.5);
    }

    50% {
        transform: scale(.55);
    }

    70% {
        transform: scale(.55);
    }

    100% {
        transform: scale(.5);
    }
}


/*Login */
.form-header {
    background-color: #248ea6 !important;
    padding: 1rem;
    margin-top: -3.13rem;
    margin-bottom: 3rem;
    color: #fff;
    text-align: center;
    border-radius: .125rem;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
}

.login-logo {
    max-width: 50%;
}


/* Register */
.calendar {
    color: #248ea6;
}

.hand {
    color: #0bb20b;
}

.pin {
    color: #d00d0d;
}

.reg-image {
    max-width: 50%;
}

.contacts-page {
    max-height: 800px;
    overflow-y: auto;
    background-color: white;
}


.cascading-admin-card .admin-up .fab, .cascading-admin-card .admin-up .far, .cascading-admin-card .admin-up .fas {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    border-radius: 3px;
}

.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -1.25rem;
}

    .cascading-admin-card .admin-up .data {
        float: right;
        margin-top: 2rem;
        text-align: right;
    }

.ap-frame {
    display: grid;
    grid-template-rows: max-content auto max-content;
    overflow: hidden;
}

.ap-header {
    grid-row-start: 1;
    background-color: transparent;
    padding: .25em;
}

.ap-content {
    height: 100%;
    width: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
    overflow:auto;
    /*    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
*/
}

.ap-footer {
    height: 50px;
    grid-row-start: 3;
    background-color: transparent;
    padding: .25em;
}

.ap-footer-logo {
    opacity: .5;
    margin: .25em;
}

.collapsed-background {
    background-color: rgba(0,0,0,.8);
    color: white;
    position: absolute;
    top: 3px;
    right: 5px;
    width: 155px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid gray;
}

    .collapsed-background:hover {
        background-color: rgb(0,0,0);
    }


.base-mpact {
    color: rgba(224 100 22 .8)
}

.base-mpact:hover {
    color: rgba(224 100 22 1)

}

.upgrade-card {
    background-color:rgb(255,255,200) !important;
}

.button-abs {
    position: absolute !important;
    border: none !important;
    padding-top: 10px !important;
}

.button-fixed {
    position: fixed !important;
    border: none !important;
    top: -12px !important;
}

.button-border-none {
    border: none !important;
}

.title-pos {
    /*top: 0.1rem !important;*/
    left: 100px !important;
    top: 5px !important;
    position: fixed !important;
    z-index: 99;
}

svg:hover {
    fill: red;
}

