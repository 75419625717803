*,
*::before,
*::after {
    box-sizing: border-box;
}

.filter-header {
    position: fixed;
    top: 70px;
    left: 30px;
    font-size: 1.2vw;
    /*color: #fff !important;*/
    font-family: "Roboto",sans-serif !important;
    z-index: 97;
    color: black !important;
}

.utilities {
    position: fixed;
    top: 85px;
    right: 25px;
}

.datePicker {
    position: fixed;
    top: 95px;
    left: 40px;
    width: 35vw;
    z-index: 99;
}

.gray-out {
    position: fixed !important;
    top: 88px !important;
    left: 0px !important;
    opacity: 0.5 !important;
    background-color: Black;
    height: 95vh !important;
    width: 100vw !important;
    z-index: 10 !important;
}

.dropdown-spacer-1a {
    position: fixed;
    top: 88px;
    margin: 10px;
    left: 15px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
}

.dropdown-spacer-1b {
    position: fixed;
    top: 88px;
    margin: 10px;
    left: 270px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
}

.dropdown-spacer-1c {
    position: fixed;
    top: 88px;
    margin: 10px;
    left: 1035px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
}

.dropdown-spacer-2a {
    position: fixed;
    top: 228px;
    margin: 10px;
    left: 14px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 103;
}

.dropdown-spacer-2b {
    position: fixed;
    top: 228px;
    margin: 10px;
    left: 270px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 103;
}

.dropdown-spacer-3a {
    position: fixed;
    top: 310px;
    margin: 10px;
    left: 14px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 102;
}

.dropdown-spacer-3b {
    position: fixed;
    top: 310px;
    margin: 10px;
    left: 270px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 102;
}

.dropdown-spacer-3c {
    position: fixed;
    top: 310px;
    margin: 10px;
    left: 525px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 102;
}

.dropdown-spacer-3d {
    position: fixed;
    top: 310px;
    margin: 10px;
    left: 780px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
    z-index: 102;
}


.dropdown-spacer {
    margin: 10px;
    padding-left: 5px;
    padding-right: 7px;
    width: 200px !important;
}

.dropdown-spacer2 {
    /*position: fixed;*/
    /*top: 145px;*/
    display: block;
    padding-left: 0px;
    padding-right: 3px;
    /*font-size: 14px !important;*/
}


.dflex-date {
    /*display: flex !important;*/
    position: fixed;
    top: 223px;
    left: 30px;
    background-color: white;
    z-index: 151;
}

.dp-1 {
    position: fixed;
    top: 215px;
    left: 20px;
}

.container-date {
    font-family: "Roboto",sans-serif !important;
    /*position: fixed;
    top: 12vh;
    left: 0.63vw;
    width: 80vw;
    max-width: 80vw;
    
    z-index: 98;*/
}

.range-container {
    font-family: "Roboto",sans-serif !important;
    z-index: 151;
}

.date-periods {
    width: 10vw;
}

.date-buttons-column {
    left: 510px;
    width: 180px !important;
}

.switch-1 {
    height: 3.98vh;
    width: 3.98vw;
    min-height: 3.98vh;
    min-width: 3.98vw;
}

.container-fp {
    position: fixed;
    top: 35px;
    margin-left: 5px;
    margin-right: 5px;
    width: 100vw;
    max-width: 100vw;
    height: 18vh;
    max-height: 18vh;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 97;
}

.date-column {
    position: fixed;
    top: 178px;
    left: 540px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 151;
}

.date-column2 {
    position: fixed;
    top: 178px;
    left: 795px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 151;
}

button {
    /*border-color: red !important;*/
    /*turn this back on*/
    border: 1px solid hsl(0, 0%, 80%) !important;
    /*border-color: hsl(0, 0%, 80%) !important;*/
}

.mt-1-fp {
    font-family: "Roboto",sans-serif !important;
    width: 242.062px;
    height: 37px;
    color: #595959 !important;
    font-size: 14px !important;
    background-color: white;
    /*border-color: red !important;*/
    /*border-color: rgb(204, 204, 204) !important;*/
    border-radius: 4px !important;
    border-width: 1px !important;
    padding: 0px;
    margin-top: 5px;
    text-align: center;
    z-index: 98;
}

.mt-1-override {
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
}

.range-button {
    font-family: "Roboto",sans-serif !important;
    width: 100px;
    height: 3.3vh;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    color: #028ae6;
    /*border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;*/
    border: none !important;
    padding: 0px;
    margin-top: 5px;
    text-align: left;
    z-index: 98;
}

.range-button-cancel {
    font-family: "Roboto",sans-serif !important;
    width: 100px;
    height: 3.98vh;
    font-size: 14px;
    background-color: white;
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
    padding: 0px;
    margin-top: 5px;
    text-align: center;
    z-index: 98;
}

.range-button-apply {
    font-family: "Roboto",sans-serif !important;
    width: 100px;
    height: 3.98vh;
    font-size: 14px;
    color: white;
    background-color: #028ae6;
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
    padding: 0px;
    margin-top: 5px;
    margin-left: 10px;
    text-align: center;
    z-index: 98;
}

.date-periods-buttons {
    position: fixed;
    Display: flex;
    width: 250px;
    top: 480px;
    left: 815px;
}

.fp-button-cancel {
    font-family: "Roboto",sans-serif !important;
    width: 140px;
    height: 3.5vh;
    font-size: 14px;
    background-color: white;
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
    padding: 0px;
    margin-top: 5px;
    text-align: center;
    z-index: 98;
}

.fp-button-apply {
    font-family: "Roboto",sans-serif !important;
    width: 140px;
    height: 3.5vh;
    font-size: 14px;
    color: white;
    background-color: #028ae6;
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
    padding: 0px;
    margin-top: 5px;
    margin-left: 10px;
    text-align: center;
    z-index: 98;
}

.fp-buttons {
    /*Display: Inline-Block;*/
    position: fixed;
    top: 70px;
    Display: flex;
    width: 250px;
    right: 75px;
}


.fp-x {
    position: fixed;
    top: 78px;
    right: 25px;
    height: 32px;
    width: 32px;
    z-index: 99;
}

.fp-i {
    position: fixed;
    top: 105px;
    left: 250px;
    height: 12px;
    width: 12px;
    z-index: 105;
}

.fp-info {
    position: fixed;
    top: 105px;
    left: 250px;
    height: 12px;
    width: 12px;
    z-index: 170;
}

.showInformation {
    position: fixed;
    top: 105px;
    left: 275px;
    height: 200px;
    width: 300px;
    background-color: white;
    border: solid;
    border-color: lightgray;
    border-radius: 4px;
    border-width: 1px;
    font-family: "Roboto",sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    z-index: 1500;
}

.center:hover {
    background-color: red;
}

.range-label {
    font-family: "Roboto",sans-serif !important;
    border: none;
    padding: 0px;
    margin-top: 5px;
    text-align: left;
    z-index: 98;
}

.mp-1-fp {
    font-family: "Roboto",sans-serif !important;
    width: 90px;
    height: 2.5vh;
    font-size: 12px;
    background-color: white;
    border-color: lightgray !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    padding: 0px;
    margin-top: 5px;
    text-align: center;
    /*z-index: 98;*/
}

.mp-2-fp {
    font-family: "Roboto",sans-serif !important;
    width: 70px;
    height: 2.5vh;
    font-size: 12px;
    background-color: white;
    border-color: lightgray !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    padding: 0px;
    margin-top: 5px;
    text-align: center;
    z-index: 98;
}

.date-col-fp {
    padding-left: 5px;
    padding-right: 5px;
}

body {
    font-family: "Roboto",sans-serif !important;
    font-size: 11.25px;
}

.funnel {
    width: 25px;
    height: 25px;
    z-index: 99;
}

.funnel-div {
    position: fixed;
    z-index: 180;
    top: 13px;
    right: 87px;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    min-height: 0;
    min-width: 0;
}

.date-range-picker {
    position: fixed;
    z-index: 105;
    top: 80px;
    right: 540px;
}

select {
    font-size: 14px;
    font-family: "Roboto",sans-serif !important;
}

.fp-btn-primary {
    /*position: fixed;*/
    background-color: #028ae6;
    color: white;
    font-weight: 500;
    z-index: 105;
}

.fp-btn-default {
    /*position: fixed;*/
    background-color: white !important;
    color: black !important;
    font-weight: 500;
    z-index: 105;
}

.fp-btn-group {
    position: fixed;
    margin-top: -10px;
    display: flex;
    z-index: 105;
}

.select-label {
    font-size: 11.25px !important;
}

.top-row-boxes {
    width: 242px !important;
    z-index: 1500;
    font-size: 14px !important;
    font-weight: 400;
    color: #595959 !important;
}

.top-row-boxes2 {
    width: 242px !important;
    z-index: 1500;
    font-size: 14px !important;
    font-weight: 400;
    color: #595959 !important;
}

.top-row-boxes3 {
    width: 242px !important;
    color: #595959 !important;
    font-size: 14px !important;
    font-weight: 400;
}

.radio-boxes {
    position: absolute;
    width: 142px !important;
    font-size: 13px;
    font-weight: 500;
    /*margin-right: 15px !important;*/
}

.force-inline {
    display: inline-flex;
}

.force-inline2 {
    margin-left: 15px !important;
    margin-right: 5px !important;
}

.radio-label {
    margin-left: 15px !important;
}

.datepicker-label {
    margin-top: 1px !important;
    margin-bottom: 4px !important;
    font-size: 11.25px !important;
}

.vertical-line {
    border-left: 2px solid hsl(0, 0%, 80%) !important;
    height: 4vh;
    top: 97px;
    left: 400px;
}

.vertical-line2 {
    border-left: 2px solid hsl(0, 0%, 80%) !important;
    height: 4vh;
}

.vertical-line3 {
    border-left: 2px solid hsl(0, 0%, 80%) !important;
    height: 4vh;
}

.react-datepicker__day {
    font-family: "Roboto",sans-serif !important;
}

.react-datepicker__day-name {
    font-family: "Roboto",sans-serif !important;
}

.fuel-spacer {
    position: fixed;
    margin-left: 5px;
    margin-top: -1px !important;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}

.check-box {
    width: 17px;
    height: 17px;
    background-color: #028ae6;
}



.switch {
    position: fixed;
}

    .switch label {
        width: 30px;
        height: 17px;
        background-color: #028ae6;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50px;
    }

    .switch input[type="checkbox"] {
        visibility: hidden;
    }

    .switch label:after {
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 50px;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: 100ms;
        background-color: white;
    }

    .switch .look:checked + label:after {
        left: 14px;
    }

    .switch .look:checked + label {
        background-color: #028ae6;
    }

.toggle-flat {
    margin-right: 15px;
    margin-top: -1px;
    font-size: 13px;
    font-weight: 200;
}

.toggle-rpm {
    margin-left: 45px;
    margin-top: -1px;
    font-size: 13px;
    font-weight: 600;
}

.expanded-dropdown {
    color: #028ae6 !important;
    font-size: 13px;
    font-weight: 400;
    border: none !important;
}

.expanded-panel {
    position: fixed;
    background-color: white;
    left: -20px;
    width: 120vw;
    height: 25vh;
    top: 230px;
}

.fp-row-1 {
    position: fixed;
    display: flex;
    top: 95px;
    left: 549px;
    width: 775px;
    z-index: 105;
}

.fp-row-2 {
    position: fixed;
    top: 176px;
    left: 30px;
    width: 97vw;
    z-index: 104;
}

.fp-row-3 {
    position: fixed;
    top: 176px;
    left: 270px;
    width: 97vw;
    z-index: 104;
}

.fp-row-4 {
    position: fixed;
    top: 192px;
    left: 516.5px;
    width: 97vw;
    z-index: 104;
}

.fp-row-5 {
    position: fixed;
    display: inline-flex;
    top: 198px;
    left: 557px;
    width: 97vw;
    z-index: 104;
}

.fp-row-6 {
    position: fixed;
    top: 198px;
    left: 677px;
    width: 97vw;
    z-index: 104;
}

.fp-row-6b {
    position: fixed;
    top: 192px;
    left: 831px;
    width: 97vw;
    z-index: 104;
}

.fp-row-7 {
    position: fixed;
    display: inline-flex;
    top: 202px;
    left: 869px;
    width: 97vw;
    z-index: 104;
}

.fp-row-8 {
    position: fixed;
    top: 202px;
    left: 1157px;
    width: 10vw;
    z-index: 104;
}

.fp-row-9 {
    position: fixed;
    top: 100px;
    left: 30px;
    width: 1291px;
    z-index: 103;
}

.fp-row-10 {
    position: fixed;
    display: flex;
    top: 400px;
    left: 30px;
    width: 75vw;
    z-index: 102;
}

.fp-row-11 {
    position: fixed;
    display: flex;
    top: 400px;
    left: 284px;
    width: 75vw;
    z-index: 102;
}

.fp-row-12 {
    position: fixed;
    top: 418px;
    left: 531.5px;
    width: 97vw;
    z-index: 102;
}


.fp-row-13 {
    position: fixed;
    top: 400px;
    left: 557px;
    width: 75vw;
    z-index: 102;
}

.fp-row-date {
    position: fixed;
    top: 95px;
    left: 555px; /*was 45px*/
    width: 510px;
    z-index: 151;
    background-color: transparent;
}

.fp-row-date2 {
    position: fixed;
    top: 170px;
    left: 545px; /*was 35px*/
    height: 358px;
    width: 630px;
    z-index: 151;
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 8px !important;
    background-color: hsl(0, 0%, 97%) !important;
}

.scroll-active-filter {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-flow: row nowrap;
    /*white-space: nowrap;*/
    /*scrollbar-width:none;
    scrollbar-face-color:blue;
    scrollbar-color: red;*/
    /*max-width: 100px;*/
}

.filter-tag-container2 {
    border: 1px solid black;
    min-height: 80px;
    height: 80px;
    width: 80%;
    max-width: 80%;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2px;
    margin-bottom: 2px;
}

.filter-tag2 {
    min-height: 40px;
    min-width: 80px;
    color: grey;
    border: 1px solid black;
}

.filter-pane {
    position: fixed;
    top: 65px;
    width: 100vw;
    height: 16vh;
    z-index: 96;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    display: grid;
    grid-template-columns: auto 30px;
    background-color: white;
}

    .filter-pane .filters {
        padding: 4.5px 0;
        margin: 0 15px;
        top: 25px;
        left: -20px;
        max-height: 75vh;
        transition: max-height .2s ease-out;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        background-color: white;
        width: 120vw;
        max-width: 120vw;
        /*z-index: 98;*/
    }

.hidden {
    display: none;
}
